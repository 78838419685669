<template>
  <ProductHeader
    class="border-b border-black/6 bg-background-extra-light py-3"
    data-test="cart-item"
    :title="title"
    :image="image"
    :brand="brand"
    :categories="categories"
    :animal-type="animalType"
    :path="path"
    :options="options"
    :quantity="quantity"
    :tracking-context-data="trackingContextData"
    :storage-climate="storageClimate"
    :frequency-in-days="frequencyInDays"
    :enable-quantity-selector="enableQuantitySelector"
    :tracking-product-data="trackingProductData"
    @product-click="$emit('product-click')"
  >
    <template #options>
      <div class="flex items-center">
        <span
          v-for="(option, index) in options"
          :key="option.nameId"
          class="mr-3 space-x-2 rounded-full bg-black px-3 py-1 text-xs text-white"
        >
          {{ option.value }}{{ index !== options.length - 1 ? ',' : '' }}
        </span>
        <div class="flex flex-wrap items-center justify-between">
          <div :class="enableQuantitySelector ? 'mr-2' : 'ml-auto'">
            <template v-if="isDiscounted">
              <span class="mr-1 font-extrabold text-sale-price">
                {{ $formatPrice(salePrice) }}
              </span>
              <span class="text-sm font-bold text-base-price line-through">
                {{ $formatPrice(basePrice) }}
              </span>
            </template>
            <span v-else class="text-lg font-bold text-price">
              {{ $formatPrice(basePrice) }}
            </span>
          </div>
        </div>
        <button
          v-if="enableQuantitySelector"
          class="-mt-1 ml-auto p-2"
          data-testid="deleteButton"
          :disabled="deleteLoading"
          @click="
            deleteLoading = true;
            quantityChange(0);
          "
        >
          <DynamicPictogram
            :name="deleteLoading ? 'spinner' : 'trash'"
            :spin="deleteLoading"
          />
        </button>
      </div>
    </template>

    <template #quantity-selector>
      <div
        v-if="enableQuantitySelector"
        data-test="cart-item-quantity-selector"
        class="w-full"
      >
        <QuantitySelector
          :show-cart-pictogram="false"
          size="sm"
          :qty="quantity"
          @before-change="beforeQuantityChange"
          @input="quantityChange"
        />
      </div>
    </template>
  </ProductHeader>
</template>

<script setup lang="ts">
import { useCartStore } from '~/stores/cart';
import { useSubscriptionStore } from '~/stores/subscription';

const { locale } = useI18n();
const gtm = useGtm();

const cartStore = useCartStore();

const props = defineProps({
  cartItemId: {
    type: [String, Number],
    default: '',
  },
  productId: {
    type: [String, Number],
    default: '',
  },
  variantId: {
    type: [String, Number],
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  path: {
    type: String,
    default: '',
  },
  regularPrice: {
    type: [String, Number],
    default: '',
  },
  specialPrice: {
    type: [String, Number],
    default: '',
  },
  brand: {
    type: Object,
    default: () => ({}),
  },
  categories: {
    type: Array,
    default: () => [],
  },
  animalType: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  quantity: {
    type: Object,
    default: () => ({
      value: 0,
    }),
  },
  enableQuantitySelector: {
    type: Boolean,
    default: true,
  },
  trackingContextData: {
    type: Object,
    default: () => ({}),
  },
  storageClimate: {
    type: String,
    default: 'ambient',
  },
  frequencyInDays: {
    type: Number,
    default: 0,
  },
});

defineEmits(['product-click']);

const subscriptionStore = useSubscriptionStore();

const quantityLoading = ref(false);
const deleteLoading = ref(false);
const newQuantity = ref(0);

const trackingProductData = computed(() => ({
  name: props.title,
  entityId: props.productId,
  price: props.specialPrice || props.regularPrice,
}));

const isDiscounted = computed(() =>
  props?.specialPrice
    ? +props.specialPrice < +(props?.regularPrice || 0)
    : false
);

const salePrice = computed(() => calculatePrice(+props.specialPrice));

const basePrice = computed(() => calculatePrice(+props.regularPrice));

const categoryIds = computed(() =>
  props.categories.map((category: any) => category?.entityId)
);

const beforeQuantityChange = (quantity: number) => {
  quantityLoading.value = true;
  newQuantity.value = quantity;
};

const quantityChange = (quantity: number) => {
  // TODO that is a very crude way to check if it's a subcription item. Make it nicer
  if (props.frequencyInDays) {
    return subscriptionQuantityChange(quantity);
  }
  return regularQuantityChange(quantity);
};

const subscriptionQuantityChange = (quantity: number) => {
  subscriptionStore.updateSubscriptionProductQuantity(
    props.productId,
    props.variantId,
    props.frequencyInDays,
    quantity
  );

  if (quantity === 0) {
    deleteLoading.value = false;
    gtm?.trackEvent(
      removeFromCartEvent(
        [
          {
            ...trackingProductData.value,
            quantity: props.quantity.value,
          },
        ],
        locale.value
      )
    );
  } else {
    quantityLoading.value = false;
  }
};

const regularQuantityChange = (quantity: number) => {
  if (quantity === 0) {
    cartStore.deleteCartItem(props.cartItemId).finally(() => {
      deleteLoading.value = false;

      gtm?.trackEvent(
        removeFromCartEvent(
          [
            {
              ...trackingProductData.value,
              quantity: props.quantity.value,
            },
          ],
          locale.value
        )
      );
    });
  } else {
    cartStore
      .updateCartItem({
        itemId: props.cartItemId,
        cartItem: {
          item: {
            productId: props.productId,
            variantId: props.variantId,
            quantity,
          },
          meta: {
            isOnSale: isDiscounted.value,
            categoryIds: categoryIds.value,
            brand: props.brand,
          },
        },
      })
      .finally(() => {
        quantityLoading.value = false;
      });
  }
};

const calculatePrice = (price: number) => {
  // When updating item quantity, precalculate new item price before response from server for improved loading experience
  if (quantityLoading.value && newQuantity.value && props.quantity?.value) {
    return (price / props.quantity.value) * newQuantity.value || price;
  }

  return price;
};
</script>
